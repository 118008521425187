/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Identifier } from "./Identifier";

export type EncounterIdentifier = {
  identifier: Identifier;
  type?: EncounterIdentifier.type;
  display?: string | null;
};

export namespace EncounterIdentifier {
  export enum type {
    ENCOUNTER = "Encounter",
  }
}
