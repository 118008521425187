import { useMutation, UseMutationOptions } from "react-query";
import reportClient from "./reports/client";
import contentClient from "./content/client";
import { validateTemplate } from "@/services/content/templates";
import {
  ApiError,
  Encounter_Input as Encounter,
  ReportOut,
  SubjectReference,
} from "./reports/report-client";
import keyFactory from "./content/keyFactory";

export const createTemplateReportQuery = (template: string) => {
  return {
    queryKey: keyFactory.templateReport(template),
    queryFn: () => contentClient.v1.getTemplateByIdV1({ templateId: template }),
  };
};
export type CreateTemplateReportQuery = typeof createTemplateReportQuery;

export const useCreateReportFromTemplate = (
  {
    subject,
    encounter,
  }: {
    subject: SubjectReference;
    encounter?: Encounter;
  },
  options: UseMutationOptions<
    ReportOut,
    ApiError | Error,
    string,
    unknown
  > = {},
) => {
  return useMutation(
    ["create-report", { subject, encounter }],
    async (templateId: string) => {
      const response = await contentClient.templates.getTemplateByIdV1({
        templateId,
      });
      const template = validateTemplate(response);
      if (!template) {
        throw new Error("Template not found");
      }

      const report = await reportClient.v1.createReportV1({
        requestBody: {
          encounter,
          subject,
          version: template.version,
          template: templateId,
          // TODO we need to filter this server side
          // @ts-expect-error
          blocks: template.blocks.filter(
            (b) => b.renderType == "rich-text-with-questionnaire",
          ),
        },
      });
      return report;
    },
    options,
  );
};
