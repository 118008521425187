/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Identifier } from "./Identifier";

export type SubjectIdentifier = {
  identifier: Identifier;
  type?: SubjectIdentifier.type;
  display?: string | null;
};

export namespace SubjectIdentifier {
  export enum type {
    PATIENT = "Patient",
  }
}
