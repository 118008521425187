/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlockIn } from "../models/BlockIn";
import type { BlockOut } from "../models/BlockOut";
import type { BlockUpdate } from "../models/BlockUpdate";
import type { JsonValue } from "../models/JsonValue";
import type { PatientIn } from "../models/PatientIn";
import type { PatientOut } from "../models/PatientOut";
import type { PatientUpdate } from "../models/PatientUpdate";
import type { ReportIn } from "../models/ReportIn";
import type { ReportOut } from "../models/ReportOut";
import type { ReportWithBlockReferences } from "../models/ReportWithBlockReferences";
import type { ReportWithTemplateReference } from "../models/ReportWithTemplateReference";
import type { UserOut } from "../models/UserOut";
import type { UserUpdate } from "../models/UserUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class V1Service {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Reports
   * Get all reports
   * @returns ReportOut Successful Response
   * @throws ApiError
   */
  public getReportsV1({
    offset,
    limit = 1000,
    sort = "modified:desc",
    encounter,
    encounterIdentifier,
    subject,
    authSessionStaging,
  }: {
    offset?: number;
    limit?: number;
    sort?: string;
    encounter?: string | null;
    /**
     * Filter by encounter identifier
     */
    encounterIdentifier?: string | null;
    /**
     * Filter by subject reference
     */
    subject?: string | number | null;
    authSessionStaging?: any;
  }): CancelablePromise<Array<ReportOut>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/reports/",
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      query: {
        offset: offset,
        limit: limit,
        sort: sort,
        encounter: encounter,
        "encounter:identifier": encounterIdentifier,
        subject: subject,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Report
   * Create a new report
   * @returns ReportOut Successful Response
   * @throws ApiError
   */
  public createReportV1({
    requestBody,
    authSessionStaging,
  }: {
    requestBody:
      | ReportIn
      | ReportWithBlockReferences
      | ReportWithTemplateReference;
    authSessionStaging?: any;
  }): CancelablePromise<ReportOut> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/v1/reports/",
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Report
   * Get a single report
   * @returns ReportOut Successful Response
   * @throws ApiError
   */
  public getReportV1({
    reportId,
    blocksEmbedded = false,
    authSessionStaging,
  }: {
    reportId: number;
    blocksEmbedded?: boolean;
    authSessionStaging?: any;
  }): CancelablePromise<ReportOut> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/reports/{report_id}",
      path: {
        report_id: reportId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      query: {
        blocks_embedded: blocksEmbedded,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Report
   * Delete a report
   * @returns void
   * @throws ApiError
   */
  public deleteReportV1({
    reportId,
    authSessionStaging,
  }: {
    reportId: number;
    authSessionStaging?: any;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/v1/reports/{report_id}",
      path: {
        report_id: reportId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Submit Report
   * Refresh table with given report id
   * @returns any Successful Response
   * @throws ApiError
   */
  public submitReportV1({
    reportId,
    authSessionStaging,
  }: {
    reportId: number;
    authSessionStaging?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/v1/reports/{report_id}/submit",
      path: {
        report_id: reportId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Sign All Blocks
   * Sign all blocks in a report
   * @returns any Successful Response
   * @throws ApiError
   */
  public signAllBlocksV1({
    reportId,
    authSessionStaging,
  }: {
    reportId: number;
    authSessionStaging?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/v1/reports/{report_id}/sign",
      path: {
        report_id: reportId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Blocks From Report
   * Get all blocks for a report
   * @returns BlockOut Successful Response
   * @throws ApiError
   */
  public getBlocksFromReportV1({
    reportId,
    authSessionStaging,
  }: {
    reportId: number;
    authSessionStaging?: any;
  }): CancelablePromise<Array<BlockOut>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/reports/{report_id}/blocks",
      path: {
        report_id: reportId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Append Block To Report
   * Create a new block
   * @returns BlockOut Successful Response
   * @throws ApiError
   */
  public appendBlockToReportV1({
    reportId,
    requestBody,
    authSessionStaging,
  }: {
    reportId: number;
    requestBody: BlockIn;
    authSessionStaging?: any;
  }): CancelablePromise<BlockOut> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/v1/reports/{report_id}/blocks",
      path: {
        report_id: reportId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Block In Report
   * Delete a block in a report
   * @returns void
   * @throws ApiError
   */
  public deleteBlockInReportV1({
    reportId,
    blockId,
    authSessionStaging,
  }: {
    reportId: number;
    blockId: number;
    authSessionStaging?: any;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/v1/reports/{report_id}/blocks",
      path: {
        report_id: reportId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      query: {
        block_id: blockId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Block At Position In Report
   * Get a block at a certain position for a report
   * @returns BlockOut Successful Response
   * @throws ApiError
   */
  public getBlockAtPositionInReportV1({
    reportId,
    position,
    authSessionStaging,
  }: {
    reportId: number;
    position: number;
    authSessionStaging?: any;
  }): CancelablePromise<BlockOut> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/reports/{report_id}/blocks/{position}",
      path: {
        report_id: reportId,
        position: position,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Insert Or Move Block At Position In Report
   * Insert a new block at a position in a report or move an existing block to a position in a report
   * @returns BlockOut Successful Response
   * @throws ApiError
   */
  public insertOrMoveBlockAtPositionInReportV1({
    reportId,
    position,
    id,
    authSessionStaging,
    requestBody,
  }: {
    reportId: number;
    position: number;
    /**
     * The id of the block to move or copy
     */
    id?: number | null;
    authSessionStaging?: any;
    requestBody?: BlockIn | null;
  }): CancelablePromise<BlockOut> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/v1/reports/{report_id}/blocks/{position}",
      path: {
        report_id: reportId,
        position: position,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      query: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Fhir Document
   * Generate a FHIR document for a report
   * @returns any Successful Response
   * @throws ApiError
   */
  public getFhirDocumentV1({
    reportId,
    extractResources = false,
    authSessionStaging,
  }: {
    reportId: number;
    extractResources?: boolean;
    authSessionStaging?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/reports/{report_id}/fhir-document",
      path: {
        report_id: reportId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      query: {
        extract_resources: extractResources,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Pdf
   * Generate a PDF of the report
   * @returns any Successful Response
   * @throws ApiError
   */
  public getPdfV1({
    reportId,
    authSessionStaging,
  }: {
    reportId: number;
    authSessionStaging?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/reports/{report_id}/pdf",
      path: {
        report_id: reportId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Html
   * Generate an HTML representation of the report
   * @returns any Successful Response
   * @throws ApiError
   */
  public getHtmlV1({
    reportId,
    authSessionStaging,
  }: {
    reportId: number;
    authSessionStaging?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/reports/{report_id}/html",
      path: {
        report_id: reportId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Plain Text
   * Generate plain text of the report
   * @returns any Successful Response
   * @throws ApiError
   */
  public getPlainTextV1({
    reportId,
    authSessionStaging,
  }: {
    reportId: number;
    authSessionStaging?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/reports/{report_id}/plain-text",
      path: {
        report_id: reportId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Table Records
   * Generate table record of the report
   * @returns any Successful Response
   * @throws ApiError
   */
  public getTableRecordsV1({
    reportId,
    authSessionStaging,
  }: {
    reportId: number;
    authSessionStaging?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/reports/{report_id}/table-records",
      path: {
        report_id: reportId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Refresh Table Record
   * Generate table record of the report
   * @returns any Successful Response
   * @throws ApiError
   */
  public refreshTableRecordV1({
    reportId,
    authSessionStaging,
  }: {
    reportId: number;
    authSessionStaging?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "HEAD",
      url: "/api/v1/reports/{report_id}/table-record",
      path: {
        report_id: reportId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Blocks
   * Get all blocks
   * @returns BlockOut Successful Response
   * @throws ApiError
   */
  public getBlocksV1({
    offset,
    limit = 1000,
    sort = "modified:desc",
    type,
    subject,
    authSessionStaging,
  }: {
    offset?: number;
    limit?: number;
    sort?: string;
    type?: string | null;
    subject?: string | null;
    authSessionStaging?: any;
  }): CancelablePromise<Array<BlockOut>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/blocks/",
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      query: {
        offset: offset,
        limit: limit,
        sort: sort,
        type: type,
        subject: subject,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Block
   * Get a single block
   * @returns BlockOut Successful Response
   * @throws ApiError
   */
  public getBlockV1({
    blockId,
    authSessionStaging,
  }: {
    blockId: number;
    authSessionStaging?: any;
  }): CancelablePromise<BlockOut> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/blocks/{block_id}",
      path: {
        block_id: blockId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Block
   * Update a block
   * @returns BlockOut Successful Response
   * @throws ApiError
   */
  public updateBlockV1({
    blockId,
    requestBody,
    authSessionStaging,
  }: {
    blockId: number;
    requestBody: BlockUpdate;
    authSessionStaging?: any;
  }): CancelablePromise<BlockOut> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/v1/blocks/{block_id}",
      path: {
        block_id: blockId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Validate Block
   * Validate blocks
   * @returns any Successful Response
   * @throws ApiError
   */
  public validateBlockV1({
    requestBody,
  }: {
    requestBody?: Array<number>;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/v1/blocks/validate",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Refresh Table Records
   * Refresh table records
   * @returns any Successful Response
   * @throws ApiError
   */
  public refreshTableRecordsV1({
    blockId,
    force = false,
    authSessionStaging,
  }: {
    blockId: number;
    force?: boolean;
    authSessionStaging?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "HEAD",
      url: "/api/v1/blocks/{block_id}/table-records",
      path: {
        block_id: blockId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      query: {
        force: force,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Block Table Records
   * Load table records
   * @returns any Successful Response
   * @throws ApiError
   */
  public getBlockTableRecordsV1({
    blockId,
    force = false,
    authSessionStaging,
  }: {
    blockId: number;
    force?: boolean;
    authSessionStaging?: any;
  }): CancelablePromise<Array<any>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/blocks/{block_id}/table_records",
      path: {
        block_id: blockId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      query: {
        force: force,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Sign
   * Sign a block in a certain report
   * @returns BlockOut Successful Response
   * @throws ApiError
   */
  public signV1({
    blockId,
    authSessionStaging,
  }: {
    blockId: number;
    authSessionStaging?: any;
  }): CancelablePromise<BlockOut> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/v1/blocks/{block_id}/sign",
      path: {
        block_id: blockId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Unsign
   * Unsign a block in a certain report
   * @returns BlockOut Successful Response
   * @throws ApiError
   */
  public unsignV1({
    blockId,
    authSessionStaging,
  }: {
    blockId: number;
    authSessionStaging?: any;
  }): CancelablePromise<BlockOut> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/v1/blocks/{block_id}/sign",
      path: {
        block_id: blockId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Contained Resources
   * Get all FHIR resources contained in a block
   * @returns JsonValue Successful Response
   * @throws ApiError
   */
  public getContainedResourcesV1({
    blockId,
    authSessionStaging,
  }: {
    blockId: number;
    authSessionStaging?: any;
  }): CancelablePromise<Array<Record<string, JsonValue>>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/blocks/{block_id}/contained",
      path: {
        block_id: blockId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Contained Resource
   * Add a FHIR resource to a block
   * @returns any Successful Response
   * @throws ApiError
   */
  public addContainedResourceV1({
    blockId,
    requestBody,
    authSessionStaging,
  }: {
    blockId: number;
    requestBody: Record<string, JsonValue>;
    authSessionStaging?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/v1/blocks/{block_id}/contained",
      path: {
        block_id: blockId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Contained Resource
   * Get a specific FHIR resource contained in a block
   * @returns any Successful Response
   * @throws ApiError
   */
  public getContainedResourceV1({
    blockId,
    resourceId,
    authSessionStaging,
  }: {
    blockId: number;
    resourceId: number;
    authSessionStaging?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/blocks/{block_id}/contained/{resource_id}",
      path: {
        block_id: blockId,
        resource_id: resourceId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Contained Resource
   * Update a FHIR resource contained in a block
   * @returns any Successful Response
   * @throws ApiError
   */
  public updateContainedResourceV1({
    blockId,
    resourceId,
    requestBody,
    authSessionStaging,
  }: {
    blockId: number;
    resourceId: number;
    requestBody: Record<string, JsonValue>;
    authSessionStaging?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/v1/blocks/{block_id}/contained/{resource_id}",
      path: {
        block_id: blockId,
        resource_id: resourceId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Contained Resource
   * Delete a FHIR resource contained in a block
   * @returns void
   * @throws ApiError
   */
  public deleteContainedResourceV1({
    blockId,
    resourceId,
    authSessionStaging,
  }: {
    blockId: number;
    resourceId: number;
    authSessionStaging?: any;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/v1/blocks/{block_id}/contained/{resource_id}",
      path: {
        block_id: blockId,
        resource_id: resourceId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Users
   * Get all users
   * @returns UserOut Successful Response
   * @throws ApiError
   */
  public getUsersV1({
    offset,
    limit = 1000,
    authSessionStaging,
  }: {
    offset?: number;
    limit?: number;
    authSessionStaging?: any;
  }): CancelablePromise<Array<UserOut>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/users/",
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      query: {
        offset: offset,
        limit: limit,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get User Me
   * Get current user
   * @returns UserOut Successful Response
   * @throws ApiError
   */
  public getUserMeV1({
    authSessionStaging,
  }: {
    authSessionStaging?: any;
  }): CancelablePromise<UserOut> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/users/me",
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Current User Data
   * Update user data
   * @returns UserOut Successful Response
   * @throws ApiError
   */
  public updateCurrentUserDataV1({
    requestBody,
    authSessionStaging,
  }: {
    requestBody: UserUpdate;
    authSessionStaging?: any;
  }): CancelablePromise<UserOut> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/v1/users/me",
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update User Data
   * Update user data
   * @returns UserOut Successful Response
   * @throws ApiError
   */
  public updateUserDataV1({
    userId,
    requestBody,
    authSessionStaging,
  }: {
    userId: number;
    requestBody: UserUpdate;
    authSessionStaging?: any;
  }): CancelablePromise<UserOut> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/v1/users/{user_id}",
      path: {
        user_id: userId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Patients
   * Get all patients
   * @returns PatientOut Successful Response
   * @throws ApiError
   */
  public getPatientsV1({
    offset,
    limit = 1000,
    identifier,
    sort,
    authSessionStaging,
  }: {
    offset?: number;
    limit?: number;
    identifier?: string | null;
    sort?: string | null;
    authSessionStaging?: any;
  }): CancelablePromise<Array<PatientOut>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/patients/",
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      query: {
        offset: offset,
        limit: limit,
        identifier: identifier,
        sort: sort,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Patient
   * Create patient
   * @returns PatientOut Successful Response
   * @throws ApiError
   */
  public createPatientV1({
    requestBody,
    authSessionStaging,
  }: {
    requestBody: PatientIn;
    authSessionStaging?: any;
  }): CancelablePromise<PatientOut> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/v1/patients/",
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Patient
   * Get all users
   * @returns PatientOut Successful Response
   * @throws ApiError
   */
  public getPatientV1({
    patientId,
    authSessionStaging,
  }: {
    patientId: number;
    authSessionStaging?: any;
  }): CancelablePromise<PatientOut> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/v1/patients/{patient_id}",
      path: {
        patient_id: patientId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Patient
   * Get all users
   * @returns PatientOut Successful Response
   * @throws ApiError
   */
  public updatePatientV1({
    patientId,
    requestBody,
    authSessionStaging,
  }: {
    patientId: number;
    requestBody: PatientUpdate;
    authSessionStaging?: any;
  }): CancelablePromise<PatientOut> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/v1/patients/{patient_id}",
      path: {
        patient_id: patientId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Patient
   * Get all users
   * @returns PatientOut Successful Response
   * @throws ApiError
   */
  public deletePatientV1({
    patientId,
    authSessionStaging,
  }: {
    patientId: number;
    authSessionStaging?: any;
  }): CancelablePromise<PatientOut> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/v1/patients/{patient_id}",
      path: {
        patient_id: patientId,
      },
      cookies: {
        auth_session_staging: authSessionStaging,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
