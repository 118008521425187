import client from "./client";
import keyFactory from "./keyFactory";

export function createFHIRR5CompositionQuery({
  reportId,
}: {
  reportId: number;
}) {
  return {
    queryKey: keyFactory.getFHIRR5Composition(reportId),
    queryFn: () => client.v1.getFhirDocumentV1({ reportId }),
    select(data: any) {
      return data?.entry?.[0]?.resource;
    },
  };
}
