/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ViewDefinitionSelect } from "./ViewDefinitionSelect";

export type SimpleViewDefinition = {
  resourceType?: SimpleViewDefinition.resourceType;
  name: string;
  version?: string | null;
  select?: Array<ViewDefinitionSelect>;
};

export namespace SimpleViewDefinition {
  export enum resourceType {
    VIEW_DEFINITION = "ViewDefinition",
  }
}
