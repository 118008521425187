/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BlockReferenceIn } from "./BlockReferenceIn";
import type { EncounterIdentifier } from "./EncounterIdentifier";
import type { SubjectIdentifier } from "./SubjectIdentifier";
import type { SubjectReference } from "./SubjectReference";
import type { SupervisorIdentifier } from "./SupervisorIdentifier";

/**
 * Model for report creation with canonical block urls and optionally a title and additional coding.
 */
export type ReportWithBlockReferences = {
  encounter?: EncounterIdentifier | null;
  subject: SubjectReference | SubjectIdentifier;
  supervisor?: SupervisorIdentifier | null;
  title?: string | null;
  language?: ReportWithBlockReferences.language;
  blocks?: Array<BlockReferenceIn>;
};

export namespace ReportWithBlockReferences {
  export enum language {
    NL_BE = "nl-BE",
    EN = "en",
    NL = "nl",
  }
}
