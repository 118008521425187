/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Identifier } from "./Identifier";

export type SupervisorIdentifier = {
  identifier: Identifier;
  type?: SupervisorIdentifier.type;
  display?: string | null;
};

export namespace SupervisorIdentifier {
  export enum type {
    PRACTITIONER = "Practitioner",
  }
}
