/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Coding } from "./Coding";
import type { LinkBlock } from "./LinkBlock";
import type { UserTimestamp } from "./UserTimestamp";

/**
 * Model for block
 */
export type BlockOut = {
  title: string;
  blockType: string;
  renderType: BlockOut.renderType;
  content: any;
  autoPopulateWithPreviousResponse?: boolean | null;
  fromBlock?: number | null;
  template?: string | null;
  version?: string | null;
  coding?: Array<Coding>;
  id: number;
  created: UserTimestamp;
  lastModified: UserTimestamp;
  signed?: UserTimestamp | null;
  links?: LinkBlock | null;
};

export namespace BlockOut {
  export enum renderType {
    QUESTIONNAIRE_V3 = "questionnaire-v3",
    QUESTIONNAIRE_V2_1 = "questionnaire-v2.1",
    QUESTIONNAIRE_V2 = "questionnaire-v2",
    TABLE_V2 = "table-v2",
    TABLE_V3 = "table-v3",
    FREE_TEXT_V1 = "free-text-v1",
    RICH_TEXT_WITH_QUESTIONNAIRE = "rich-text-with-questionnaire",
    QA_RICH_TEXT = "qa-rich-text",
    TIMESTAMPED_RICH_TEXT = "timestamped-rich-text",
    TABLE_RICH_TEXT = "table-rich-text",
  }
}
